// version 0.0.0
export default {
  workspace: {
    transferOwnership: 'New Captain 🚢',
    TransferOwnershipDesc:
      'Choose a trusted friend or colleague to take the helm as the Captain of your workspace. With this title comes full control and exclusive ownership.',
    TransferOwnershipDesc2:
      'Once you hand over the keys, your role as the owner will end. Make sure your Captain is ready to steer the ship!',
    workspaces: 'Workspaces',
    overview: 'Space Overview',
    billing: 'Billing',
    createOn: 'Created on',
    createdBy: 'Created by',
    transferOwner: 'Transfer Ownership',
    redLock: 'Code Red Lock',
    spaceLogo: 'Space Logo',
    spaceDesc:
      'Let’s personalize your workspace! Upload a 40x40 px company logo with a transparent background for optimal display. ',
    upload: 'Upload',
    freeTrial: '1 Month Free',
    SubscriptionExpired: 'Expired',
    LogoUpdated: 'Logo updated. ',
    peopleTitle: 'People',
    upcomingTitle: 'Upcoming',
    BillingPayments: 'Billing & Payments',
    people: 'People',
    invite: 'Invite',
    add: 'add',
    nominate: 'Nominate',
    personalData: 'Personal Data Notice',
    personalDataDesc:
      "You're about to pass over the full ownership of your account, which includes all linked credit cards, billing details, and related data. This decision carries considerable weight as it's irreversible. Please, move forward only if you have full trust in the new owner and understand the implications thoroughly",
    members: 'Members',
    invitePeople: 'Extend Workspace Invitations',
    inviteDesc:
      'Extend a digital welcome! Quickly add team members by entering their phone numbers below. You control their roles and camera permissions.',
    inviteWith: 'Invite by phone number',
    inviteSuccess: 'Invite message sent for user',
    select: 'Select',
    YouNominated: 'You are nominated ',
    pending: 'Invited',
    sendNow: 'Send Now',
    sendInvite: 'Send Invite',
    member: 'Member',
    inviteHint: 'If you want to add a number, please include the initial code first.',
    successAdd: 'users added!',
    hintInvite: 'Example: +1 (209) 999 9999',
    workspaceDeleteSuccess: 'workspace was delete successfully',
    deviceDeleteSuccess: 'device was delete  successfully',
    billingInvoicing: 'My Billing & Invoicing ',
    label: {
      WorkspaceName: 'Workspace Name',
      Description: 'Description',
      typeHere: 'Type Here',
      nameSuccess: 'Updated!',
      errormsg: 'Oops! Something went wrong. ',
      descSuccess: 'Updated!'
    },
    actions: {
      save: 'Save'
    },
    devices: {
      title: 'Devices',
      connect: 'Watch how-to connect tutorial',
      bridgeTitle: 'Cameras via Bridge'
    },
    upcoming: {
      title: 'Upcoming',
      nextPayment: 'Next Payment',
      description:
        "You have the flexibility to upgrade or cancel your plan at any time, and the best part is that it's \n" +
        'completely free of charge.',
      today: 'Today',
      monthly: 'monthly',
      cta: 'Billing & Invoicing',
      training: 'Training',
      autoRenewal: 'Auto Renewal',
      freezeTitle: 'Pause Upcoming Payments',
      freezeSubtitle:
        'To deactivate auto-renewal, please enter the name of your Workspace below for confirmation.',
      workspaceName: 'Type “{data}” ',
      getDiscountedPricing: 'Get Discounted Pricing',
      turnOff: 'Turn Off',
      freezeNote:
        "I acknowledge that by hitting 'Turn Off', I am venturing into a digital abyss, halting my subscription and cutting off recurring payments. This grave decision puts me on a precipice, fully aware that failing to retreat will lead to the catastrophic vanishing of all my treasured video footage and personal data within the next month, a loss akin to a personal data apocalypse.",
      riskLostData: 'Risk of Data Loss',
      riskLostDataText:
        'Please note that choosing to keep your auto-renewal off places you at a crucial crossroads. This important decision, if not reconsidered, could result in the total and irreversible loss of all your valued video footage and personal data within the next month—an event akin to a devastating personal data catastrophe.'
    },
    selection: {
      description: {
        ready: 'Ready to peek into your world?',
        simply: 'Simply select your workspace and voilà',
        begin: '– let the show begin! 🍿'
      },
      invited:
        '{ownerName} invited you to join <span class="text-primary-200 text-nowrap">{workspace}</span>',
      actions: {
        newWorkspace: 'New Workspace'
      },
      userLocked: {
        title: 'Locked Workspace Due to Billing Issues',
        description:
          'Looks like {workspace} by {ownerName} is taking a break due to billing hiccups. Hang tight! Your workspace captain will have everything back on track soon!'
      },
      expiredOwner: {
        title: 'Expired, please check the validity.',
        description:
          "Let's get you back on track—for an uninterrupted monitoring experience, please add a valid payment method to your {workspace} soon.\nThanks a bunch!"
      },
      default:
        '<span class="text-primary-200">{workspace}</span> by <span class="text-primary-200">{ownerName}</span>'
    },
    delete: {
      title: 'We’re sad to see you go ☹️',
      subtitle:
        'We really appreciate the time you spent with us, can you let us know what went wrong?',
      item1: 'I had trouble setting up my cameras.',
      subItem1:
        'As a versatile open platform, we support 3,000+ camera models from top brands, securing compatibility with your existing security investments',
      item2: 'I had trouble with platform’s usability. ',
      subItem2:
        'As a versatile open platform, we support 3,000+ camera models from top brands, securing compatibility with your existing security investments',
      item3: 'I am choosing to go with your competition.',
      item4: "I don't see any added value here.",
      item5: 'I can’t afford the pricing.',
      other: 'Other',
      otherPlaceholder: 'Please type here.',
      noteTitle: 'Data Deletion Notice',
      noteContent:
        'Please be advised, your invaluable data is slated for irreversible deletion within \n' +
        'the next 72 hours. This action is permanent and cannot be undone. To avoid this imminent loss, your immediate intervention is crucial. We strongly urge you to take the necessary steps to preserve your data. Failure to do so will result in the irrevocable loss of your information.',
      permanentlyDelete: 'Permanently Delete',
      talkToSales: 'Talk to Sales',
      talkToSupport: 'Talk to Support',
      FreezeWorkspace: 'Freeze Upcoming Bills',
      permanentlyDeleteTitle: 'Permanent Workspace Deletion ☠️',
      permanentlyDeleteSubTitle: 'To confirm, type in the Workspace name below.',
      permanentlyDeleteContent:
        "By selecting 'Delete', I acknowledge that I am choosing to discontinue Arcadian Orbit Inc.'s services. I understand that this will result in the immediate loss of all my valuable recorded video footage and personal data.",
      deleteWorkspaceInput: 'Type “{data}” *',
      DeleteTitle: 'We hate goodbyes, but we respect your decision',
      DeleteSubTitle:
        'To move forward with the deletion of your workspace, start by making sure all your connected devices are removed. After this is done, just send us a brief message. We assure you of a smooth and hassle-free cancellation of your account.',
      DeleteContent:
        'I acknowledge that by requesting {PermanentDeletion} of my workspace, I am venturing into a digital abyss, halting my subscription and cutting off recurring payments. This grave decision puts me on a precipice, fully aware that failing to retreat will lead to the catastrophic vanishing of all my treasured video footage and personal data within the next month, a loss akin to a personal data apocalypse.',
      PermanentDeletion: "'Permanent Deletion'",
      deleteLastSeatTitle: 'Trouble in Paradise? How About We Pause Your Upcoming Payments?',
      deleteLastSeatContent:
        'Every active workspace has a reserved virtual seat. If you remove your last virtual seat, your account will be permanently deleted. If that’s your final decision, please contact our support team through our website. Alternatively, you can turn off your auto-renewal to freeze all upcoming payments directly from your workspace billing tab until the end of time!',
      deleteCameraTitle:
        'Ready to wipe out {workspace} and its entire cloud collection (recordings, clips, and analytics)? Type in your name to make it official.',
      confirmCameraDelete: 'Delete',
      deleteCameraText:
        "By selecting 'Delete Camera', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated camera data."
    },
    operationHours: {
      title: 'Hours of Operation',
      todayData: 'Open today ',
      notSet: 'Not Set',
      closed: 'Closed',
      successUpdate: 'Saved! '
    }
  }
}
