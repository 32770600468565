import type { PlayerCore } from '@/player/lib/player/player-core'
import type { PointDto } from '@/lib/api/services/ai/analytics/analytic.interface'
import { AnalyticDetailedTyped, AnalyticEventTypes } from '@/lib/api'

export class AnalyticService {
  constructor(protected readonly core: PlayerCore) {}

  async updateMotion(
    id: string,
    name: string,
    minThreshold: number,
    sensitivity: number,
    points: PointDto[]
  ) {
    const data = await this.core.apis.updateAnalytics(id, {
      type: AnalyticEventTypes.SwMotion,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name,
        usage: AnalyticDetailedTyped.motionSoftware
      }
    })
    this.core.analyticManager2.updateAnalyticRef(id, data.item)
  }

  async createMotion(name: string, minThreshold: number, sensitivity: number, points: PointDto[]) {
    const data = await this.core.apis.createAnalytics({
      type: AnalyticEventTypes.SwMotion,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name,
        usage: AnalyticDetailedTyped.motionSoftware
      }
    })
    this.core.analyticManager2.addAnalytic(data.item)
  }

  async removeMotion(id: string) {
    await this.core.apis.deleteAnalytics(id)
    this.core.analyticManager2.removeAnalyticById(id)
  }

  async updateFence(
    id: string,
    minThreshold: number,
    sensitivity: number,
    name: string,
    usage = AnalyticDetailedTyped.crossLineSoftware,
    points: PointDto[]
  ) {
    const ref = this.core.analyticManager2.getAnalyticById(id)
    const data = await this.core.apis.updateAnalytics(id, {
      type: AnalyticEventTypes.SwFence,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name: name || ref?.ref?.configs.features?.name || 'Entry/Exit Point',
        usage
      }
    })
    this.core.analyticManager2.updateAnalyticRef(id, data.item)
  }

  async createFence(
    minThreshold: number,
    sensitivity: number,
    points: PointDto[],
    name: string,
    usage = AnalyticDetailedTyped.crossLineSoftware
  ) {
    const data = await this.core.apis.createAnalytics({
      type: AnalyticEventTypes.SwFence,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 0
      },
      features: {
        name,
        usage
      }
    })
    this.core.analyticManager2.addAnalytic(data.item)
  }

  async createCrossLineBothWay(
    minThreshold: number,
    sensitivity: number,
    points: PointDto[],
    name: string,
    labels: [string, string]
  ) {
    const data = await this.core.apis.createAnalyticsBulk([
      {
        type: AnalyticEventTypes.SwFence,
        config: {
          sensitivity,
          points: [points[0], points[1]],
          minThreshold,
          alarmDelay: 0
        },
        features: {
          name,
          usage: AnalyticDetailedTyped.crossLineSoftware,
          labelName: labels[0]
        }
      },
      {
        type: AnalyticEventTypes.SwFence,
        config: {
          sensitivity,
          points: [points[1], points[0]],
          minThreshold,
          alarmDelay: 0
        },
        features: {
          name,
          usage: AnalyticDetailedTyped.crossLineSoftware,
          labelName: labels[1]
        }
      }
    ])
    for (const item of data.item) {
      this.core.analyticManager2.addAnalytic(item)
    }
  }

  async updateCrossLineBothWay(
    id: string,
    relatedTo: string | undefined,
    minThreshold: number,
    sensitivity: number,
    points: PointDto[],
    name: string,
    labels: [string, string]
  ) {
    const id2 = this.core.analyticManager2.analytics.find(
      (a) => a.identity.ref?.relatedTo === relatedTo && a.identity.ref?.id !== id
    )?.identity.ref?.id
    if (id2) {
      const data = await this.core.apis.updateAnalyticsBulk([
        {
          id,
          type: AnalyticEventTypes.SwFence,
          config: {
            points,
            sensitivity,
            minThreshold,
            alarmDelay: 0
          },
          features: {
            name,
            usage: AnalyticDetailedTyped.crossLineSoftware,
            labelName: labels[0]
          }
        },
        {
          id: id2,
          type: AnalyticEventTypes.SwFence,
          config: {
            points: [points[1], points[0]],
            sensitivity,
            minThreshold,
            alarmDelay: 0
          },
          features: {
            name,
            usage: AnalyticDetailedTyped.crossLineSoftware,
            labelName: labels[1]
          }
        }
      ])
      for (const item of data.item) {
        this.core.analyticManager2.updateAnalyticRef(item.id, item)
      }
    } else {
      throw new Error('analytic not found!')
    }
  }

  async removeCrossLineBothWay(id: string, relatedTo?: string) {
    const id2 = this.core.analyticManager2.analytics.find(
      (a) => a.identity.ref?.relatedTo === relatedTo && a.identity.ref?.id !== id
    )?.identity.ref?.id
    if (id2) {
      await this.core.apis.removeAnalyticsBulk([id, id2])
      this.core.analyticManager2.removeAnalyticById(id)
      this.core.analyticManager2.removeAnalyticById(id2)
    } else {
      throw new Error('analytic not found!')
    }
  }

  async removeFence(id: string) {
    await this.core.apis.deleteAnalytics(id)
    this.core.analyticManager2.removeAnalyticById(id)
  }

  async createLeftAndMissedObject(
    name: string,
    minThreshold: number,
    sensitivity: number,
    points: PointDto[]
  ) {
    const data = await this.core.apis.createAnalytics({
      type: AnalyticEventTypes.SwLeftMissed,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 5
      },
      features: {
        name,
        usage: AnalyticDetailedTyped.missedObject
      }
    })
    this.core.analyticManager2.addAnalytic(data.item)
  }

  async updateLeftAndMissedObject(
    id: string,
    name: string,
    minThreshold: number,
    sensitivity: number,
    points: PointDto[]
  ) {
    const ref = this.core.analyticManager2.getAnalyticById(id)
    const data = await this.core.apis.updateAnalytics(id, {
      type: AnalyticEventTypes.SwLeftMissed,
      config: {
        sensitivity,
        points,
        minThreshold,
        alarmDelay: 5
      },
      features: {
        name: name || ref?.ref?.configs.features?.name || 'Valuable Item',
        usage: AnalyticDetailedTyped.missedObject
      }
    })
    this.core.analyticManager2.updateAnalyticRef(id, data.item)
  }

  async removeLeftAndMissedObject(id: string) {
    await this.core.apis.deleteAnalytics(id)
    this.core.analyticManager2.removeAnalyticById(id)
  }
}
