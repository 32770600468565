<template>
  <div :class="`${totalLoading ? 'h-screen overflow-hidden' : ''}`">
    <loading v-if="totalLoading" />
    <RouterView
      v-if="
        !totalLoading ||
        (totalLoading &&
          routesForceRouterView.some((route) => {
            return route === router.currentRoute.value.name
          }))
      "
    />
  </div>
  <Toast :base-z-index="1205" />
</template>

<script lang="ts" setup>
import { RouterView, useRouter } from 'vue-router'
import { onMounted, watch } from 'vue'
import { useNetwork } from '@vueuse/core'
import Toast from 'primevue/toast'
import { useThemeStore } from '@/stores/Theme/useThemeStore'
import { storeToRefs } from 'pinia'
import { useApplicationStore } from '@/stores/useApplicationStore'
import loading from '@/components/general/loading/loading.vue'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { useToast } from 'primevue/usetoast'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const { isOnline } = useNetwork()
const { totalLoading } = storeToRefs(useApplicationStore())

const routesForceRouterView = ['authentication', 'recovery-password', 'reset-password']

// @ts-ignore
watch(
  () => isOnline.value,
  (value) => {
    if (!value) router.push({ name: 'interruption' })
    else router.back()
  }
)

const eventBus = useEventBus()
const toast = useToast()
const { t } = useI18n()

onMounted(async () => {
  console.log('application boot version 4.30.0')

  let theme = localStorage.getItem('theme') || sessionStorage.getItem('theme')
  if (theme) useThemeStore().changeTheme(theme)
  else useThemeStore().changeTheme('dark')
  eventBus.on('waitForDoActions', () => {
    toast.add({
      severity: 'success',
      summary: 'Hang Tight...',
      detail: t('errorMessage.waitForDoActions'),
      life: 7000
    })
  })

  showToastListener()
  removeToastListener()
  removeToastAll()
})

function showToastListener() {
  eventBus.on('toast.add', (e: any) => {
    toast.add({
      severity: e.severity,
      summary: e.summary,
      detail: e.detail,
      life: e.life,
      closable: e.closable,
      group: e.group,
      contentStyleClass: e.contentStyleClass,
      styleClass: e.styleClass
    })
  })
}

function removeToastListener() {
  eventBus.on('toast.remove', (e: any) => {
    toast.remove({
      severity: e.severity,
      summary: e.summary,
      detail: e.detail,
      life: e.life,
      closable: e.closable,
      group: e.group,
      contentStyleClass: e.contentStyleClass,
      styleClass: e.styleClass
    })
  })
}

function removeToastAll() {
  eventBus.on('toast.removeAllGroups', () => {
    toast.removeAllGroups()
  })
}
</script>

<style>
body {
  font-family: Montserrat, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
